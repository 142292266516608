/* Provide sufficient contrast against white background */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Qwitcher+Grypen:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import "rsuite/dist/rsuite.css";



@layer base {
  html,
  body,
  #root {
    font-family: "Poppins", sans-serif, ui-sans-serif, system-ui, -apple-system,
      BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol", "Noto Color Emoji";
  }

  .modal-body {
    @apply absolute left-1/2 top-[80px] transform -translate-x-1/2 -translate-y-1/2 shadow-lg 2xl:rounded-24 outline-none px-5 lg:px-10 2xl:px-20 py-5 overflow-y-scroll h-full w-full lg:max-w-3xl rounded-none lg:h-5/6 2xl:h-[800px] lg:rounded-14 bg-white;  }

  .mobile-modal-body {
    @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-lg 2xl:rounded-24 outline-none px-5 lg:px-10 2xl:px-20 py-5 overflow-y-scroll h-full w-full lg:max-w-7xl rounded-none lg:h-5/6 2xl:h-225 lg:rounded-14 bg-white;
  }
  .modal-body::-webkit-scrollbar {
    @apply hidden;
  }
  .modal-body > .scrollbar-hide::-webkit-scrollbar {
    @apply hidden;
  }
  .mobile-modal-body::-webkit-scrollbar {
    @apply hidden;
  }
  .mobile-modal-body > .scrollbar-hide::-webkit-scrollbar {
    @apply hidden;
  }


  a {
    text-decoration: none;
    cursor: pointer;
  }

  #dropdown > #menu{
    background-color: "red";
    transition-property:all;
    transition-delay: 1500ms;
  }
  li > ul {
    transform: translatex(100%) scale(0);
  }
  #sectors:hover > #menu-sector{
    transform: translatex(100%) scale(1);
  }
  #services:hover > #menu-services-item{
    transform: translatex(100%) scale(1);
  }
  #corporate-services-item{
    visibility: hidden;
  }
  #corp-services:hover > #corporate-services-item{
    visibility: visible;
    transform: translatex(100%) scale(1);
  }
  #individual-services-item{
    visibility: hidden;
  }
  #individual-services:hover > #individual-services-item{
    visibility: visible;
    transform: translatex(100%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }
  input[type=file]::file-selector-button {
    display: none;
  }

  input::placeholder {
    font-size: 15px;
    color: #A9A9A9;
    font-weight: 300;
}

  input[type=file] {
    padding-top: 70px;
    padding-left: -70px;
    font-size: small;
    text-align: center;
    font-family:'Poppins',
  }

  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}
